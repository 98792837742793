import secureLocalStorage from "react-secure-storage";

import CryptoJS from "crypto-js";

const secretKey = "redux-central-system-of-otb"; // Use a strong, unique key

// secureLocalStorage.setItem("acessTokens", JSON.stringify(acessTokens));

const decryptData = (encryptedData) => {
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
  return JSON.parse(decryptedString); // Convert string back to object/array
};

const encryptData = (data) => {
  const stringData = JSON.stringify(data); // Convert object/array to string
  return CryptoJS.AES.encrypt(stringData, secretKey).toString();
};
let userInfo = localStorage.getItem("userInfo");
let userData = userInfo ? decryptData(userInfo) : null;
let highlitedData = JSON.parse(secureLocalStorage.getItem("highlitedData"));
let wish = localStorage.getItem("wishlist");
let wishInfo = localStorage.getItem("wishInfo");
let wishDetails = wishInfo ? decryptData(wishInfo) : null;
let cart = localStorage.getItem("cartlist");
let categories = JSON.parse(secureLocalStorage.getItem("categoriesList"));
let visitedProducts = JSON.parse(secureLocalStorage.getItem("visitedProducts"));
let advanceFilters = JSON.parse(secureLocalStorage.getItem("advance"));

let accountData = localStorage.getItem("profile");

let profileData = accountData ? decryptData(accountData) : null;

const userAccessToken = userData?.userAccessToken;
const user_id = userData?.user_id;
const earning_point = userData?.earning_point;
const points = userData?.points;
const redeemed_point = userData?.redeemed_point;
const role = userData?.role;
const privileges = userData?.privileges;
const name = userData?.name;

const wishlist_items_count = wishDetails?.wishlist_items_count;
const wishIdz = wishDetails?.wishIdz;

if (!userData) {
  userData = {};
}
if (!wishDetails) {
  wishDetails = {};
}

if (!highlitedData) {
  highlitedData = {};
}
if (!advanceFilters) {
  advanceFilters = {};
}

const initialState = {
  userAccessToken: userAccessToken ? userAccessToken : null,
  user_id: user_id ? user_id : null,
  earning_point: earning_point ? earning_point : null,
  points: points ? points : null,
  redeemed_point: redeemed_point ? redeemed_point : null,
  role: role ? role : null,
  privileges: privileges ? privileges : null,
  name: name ? name : null,

  wishlist_items_count: wishlist_items_count ? wishlist_items_count : 0,
  wishIdz: wishIdz ? wishIdz : [],
  highlited: highlitedData ? highlitedData : {},

  wishList: wish ? decryptData(wish) : [],
  cartList: cart ? decryptData(cart) : [],
  categoriesList: categories ? categories : [],
  visited: visitedProducts ? visitedProducts : [],
  advance: advanceFilters ? advanceFilters : {},
  info: profileData ? profileData : {},
};

const reducer = (state = initialState, action) => {
  if (action.type === "userLogin") {
    userData.userAccessToken = action.payload.userAccessToken;
    userData.user_id = action.payload.user_id;
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;
    userData.role = action.payload.role;

    userData.privileges = action.payload.privileges;
    userData.name = action.payload.name;
    const user = encryptData(userData);

    localStorage.setItem("userInfo", user);

    wishDetails.wishlist_items_count = action.payload.wishlist_items_count;
    wishDetails.wishIdz = action.payload.wishIdz;
    const wishData = encryptData(wishDetails);
    localStorage.setItem("wishInfo", wishData);

    profileData = action.payload.info;
    let account = encryptData(profileData);

    localStorage.setItem("profile", account);

    return {
      ...state,
      userAccessToken: action.payload.userAccessToken,
      user_id: action.payload.user_id,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
      role: action.payload.role,
      wishlist_items_count: action.payload.wishlist_items_count,

      wishIdz: action.payload.wishIdz,
      privileges: action.payload.privileges,
      name: action.payload.name,
      info: action.payload.info,
    };
  } else if (action.type === "userLogOut") {
    localStorage.removeItem("userInfo");
    localStorage.removeItem("wishlist");
    secureLocalStorage.removeItem("categoriesList");
    secureLocalStorage.removeItem("highlitedData");
    localStorage.removeItem("cartlist");
    secureLocalStorage.removeItem("visitedProducts");
    secureLocalStorage.removeItem("advance");
    localStorage.removeItem("profile");
    localStorage.removeItem("wishInfo");

    return {
      ...state,
      userAccessToken: null,
      user_id: null,
      earning_point: null,
      redeemed_point: null,
      points: null,
      role: null,
      privileges: null,
      name: null,
      wishlist_items_count: 0,

      wishIdz: [],
      wishList: [],
      highlited: {},
      info: {},
      categoriesList: [],
      cartList: [],
      advance: {},
      visited: [],
    };
  } else if (action.type === "wishCount") {
    wishDetails.wishlist_items_count = action.payload.count;
    wishDetails.wishIdz = action.payload.idz;
    const wishData = encryptData(wishDetails);
    localStorage.setItem("wishInfo", wishData);

    return {
      ...state,
      wishlist_items_count: action.payload.count,
      wishIdz: action.payload.idz,
    };
  } else if (action.type === "wishCountUpdate") {


    return {
      ...state,
      wishlist_items_count: action.payload.count,
      wishIdz: action.payload.idz,
    };
  } else if (action.type === "updateName") {
    userData.name = action.payload;

    const user = encryptData(userData);
    localStorage.setItem("userInfo", user);

    return {
      ...state,
      name: action.payload,
    };
  } else if (action.type === "pointsUpdate") {
    userData.earning_point = action.payload.earning_point;
    userData.redeemed_point = action.payload.redeemed_point;
    userData.points = action.payload.points;

    const user = encryptData(userData);
    localStorage.setItem("userInfo", user);

    return {
      ...state,
      earning_point: action.payload.earning_point,
      redeemed_point: action.payload.redeemed_point,
      points: action.payload.points,
    };
  } else if (action.type === "infoUpdate") {
    profileData = action.payload;

    let account = encryptData(profileData);

    localStorage.setItem("profile", account);

    return {
      ...state,
      info: action.payload,
    };
  } else if (action.type === "infoUpdateFromStorage") {
    return {
      ...state,
      info: action.payload,
    };
  } else if (action.type === "highlited") {
    highlitedData = action.payload;

    secureLocalStorage.setItem("highlitedData", JSON.stringify(highlitedData));

    return {
      ...state,
      highlited: action.payload,
    };
  } else if (action.type === "wishListAdd") {
    const wish = encryptData(action.payload);

    localStorage.setItem("wishlist", wish);
    return {
      ...state,
      wishList: action.payload,
    };
  } else if (action.type === "wishListUpdate") {
    return {
      ...state,
      wishList: action.payload,
    };
  } else if (action.type === "storeCategories") {
    categories = action.payload;

    secureLocalStorage.setItem("categoriesList", JSON.stringify(categories));

    return {
      ...state,
      categoriesList: action.payload,
    };
  } else if (action.type === "cartAdd") {
    const cart = encryptData(action.payload);

    localStorage.setItem("cartlist", cart);

    return {
      ...state,
      cartList: action.payload,
    };
  } else if (action.type === "cartUpdate") {
    return {
      ...state,
      cartList: action.payload,
    };
  } else if (action.type === "saveVisit") {
    visitedProducts = action.payload;
    secureLocalStorage.setItem(
      "visitedProducts",
      JSON.stringify(visitedProducts)
    );

    return {
      ...state,
      visited: action.payload,
    };
  } else if (action.type === "advanceFilters") {
    advanceFilters = action.payload;
    secureLocalStorage.setItem("advance", JSON.stringify(advanceFilters));

    return {
      ...state,
      advance: action.payload,
    };
  } else if (action.type === "userUpdateFromStorage") {
    return {
      ...state,
      userAccessToken: action?.payload?.userAccessToken || null,
      user_id: action?.payload?.user_id || null,
      earning_point: action?.payload?.earning_point || null,
      redeemed_point: action?.payload?.redeemed_point || null,
      points: action?.payload?.points || null,
      role: action?.payload?.role || null,
      privileges: action?.payload?.privileges || null,
      name: action?.payload?.name || null,
    };
  } else {
    return state;
  }
};
export default reducer;
